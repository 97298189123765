<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="() => $router.go(-1)" :disabled="false">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Inventário de Habilidades Escolares Educação Especial
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-if="turma && aluno">
          <v-card-title>Relatório</v-card-title>
          <v-card-text>
            <h4>Turma: {{ turma.descricao }}</h4>
            <h4>Aluno: {{ aluno.nomecompleto }}</h4>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <div v-for="diario in gestao_de_aulas" :key="diario.id">
              Professor(a): {{ diario.professor?.nome }} | Disciplina:
              {{ diario.disciplina?.descricao }}
            </div>
          </v-card-text>
          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn
              :disabled="serie >= 1 && serie <= 3"
              class="caption ml-2"
              @click="show = !show"
              text
              >Legendas para auxiliar no lançamento do inventário de habilidades escolares</v-btn
            >

            <v-spacer></v-spacer>

            <v-btn icon @click="show = !show">
              <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>

              <v-card-text>
                <v-chip v-for="(legenda, i) in legendas" :key="i" class="ma-2" small>
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;{{ legenda }}
                </v-chip>
                <v-divider></v-divider>
                <v-chip v-for="(style, i) in styleClass" :key="i" class="ma-2" small :color="style">
                  <strong>{{ i }}</strong
                  >&nbsp;-&nbsp;lançamento(s)
                </v-chip>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab
            v-for="item of $constants.bimestresInventarioHabilidades"
            :key="item.value"
            :disabled="serie >= 1 && serie <= 3 && item.value === 0"
            @click="mudarAba(item.value)"
          >
            {{ item.desc }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-simple-table class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <tbody v-for="parent of sistemaBNCC" :key="parent.id">
                <tr>
                  <td colspan="5" class="text-h6">{{ parent.descricao }}</td>
                </tr>
                <tr v-for="child in parent.children" :key="child.id" :class="changeColor(child)">
                  <td>
                    <span class="font-weight-medium">
                      {{ incrementId() }}. {{ child.descricao }}
                    </span>
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_b1"
                      label="B1"
                      outlined
                      dense
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_b2"
                      label="B2"
                      outlined
                      dense
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_b3"
                      label="B3"
                      outlined
                      dense
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      class="mt-4"
                      :items="opcoes"
                      v-model="notas[child.id].nota_b4"
                      label="B4"
                      outlined
                      dense
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table class="elevation-1" v-show="tab_atual == 1">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-select
                      v-model="componente_curricular"
                      :items="computedComponentes"
                      label="Componente Curricular"
                      :item-text="(item) => item.descricao"
                      item-value="id"
                      outlined
                      dense
                      @change="fetchComponenteData"
                    ></v-select>
                  </td>
                </tr>
                <tr
                  v-if="componente_curricular"
                  v-for="campo in camposDoComponente"
                  :key="campo.id"
                >
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos[campo.id]">
                      <template v-slot:label>
                        <div>{{ campo.label }}</div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-simple-table class="elevation-1" v-show="tab_atual == 2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-select
                      v-model="componente_curricular"
                      :items="computedComponentes"
                      label="Componente Curricular"
                      :item-text="(item) => item.descricao"
                      item-value="id"
                      outlined
                      dense
                      @change="fetchComponenteData"
                    ></v-select>
                  </td>
                </tr>
                <tr
                  v-if="componente_curricular"
                  v-for="campo in camposDoComponente"
                  :key="campo.id"
                >
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos[campo.id]">
                      <template v-slot:label>
                        <div>{{ campo.label }}</div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-1" v-show="tab_atual == 3">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-select
                      v-model="componente_curricular"
                      :items="computedComponentes"
                      label="Componente Curricular"
                      :item-text="(item) => item.descricao"
                      item-value="id"
                      outlined
                      dense
                      @change="fetchComponenteData"
                    ></v-select>
                  </td>
                </tr>
                <tr
                  v-if="componente_curricular"
                  v-for="campo in camposDoComponente"
                  :key="campo.id"
                >
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos[campo.id]">
                      <template v-slot:label>
                        <div>{{ campo.label }}</div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-1" v-show="tab_atual == 4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="5" class="text-h6">
                    <v-select
                      v-model="componente_curricular"
                      :items="computedComponentes"
                      label="Componente Curricular"
                      :item-text="(item) => item.descricao"
                      item-value="id"
                      outlined
                      dense
                      @change="fetchComponenteData"
                    ></v-select>
                  </td>
                </tr>
                <tr
                  v-if="componente_curricular"
                  v-for="campo in camposDoComponente"
                  :key="campo.id"
                >
                  <td colspan="5" class="text-h6">
                    <v-textarea v-model="resumos[campo.id]">
                      <template v-slot:label>
                        <div>{{ campo.label }}</div>
                      </template>
                    </v-textarea>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-col cols="12">
          <v-btn color="success" :loading="promiseCount > 0" block @click="salvarNotas"
            >Enviar Relatório</v-btn
          >
        </v-col>
      </template>
    </v-card>
  </main-template>
</template>

<script>
import { $constants } from "@/plugins/constants.js";

export default {
  data() {
    return {
      componentes: $constants.componentes,
      campos: $constants.campos,
      camposEspeciais: $constants.camposEspeciais,
      legendas: null,
      opcoes: null,
      sistemaBNCC: null,
      notas: {},
      aluno: null,
      matricula: null,
      turma: null,
      serie: null,
      gestao_de_aulas: null,
      promiseCount: 0,
      tab_atual: 0,
      resumos: {
        bimestre: "",
        matricula_id: parseInt(this.$route.params.matricula_id, 10),
        componente_curricular_id: "",
        objeto_conhecimento: "",
        metodologia: "",
        recursos_didaticos: "",
        avaliacao: "",
      },
      styleClass: [
        "red lighten-4",
        "yellow accent-1",
        "orange accent-1",
        "lime lighten-3",
        "light-green lighten-1",
      ],
      show: false,
      componente_curricular: "",
    };
  },
  mounted() {
    this.currentId = 1;
  },
  computed: {
    computedComponentes() {
      if (this.serie >= 1 && this.serie <= 3) {
        return this.componentes.filter((comp) => comp.id === 1 || comp.id === 2 || comp.id === 10);
      }
      if (this.serie >= 4 && this.serie <= 8) {
        return this.componentes.filter((comp) => comp.id === 1 || comp.id === 2 || comp.id === 10);
      }
      if (this.serie >= 9 && this.serie <= 12) {
        return this.componentes;
      }
      return [];
    },

    camposDoComponente() {
      const campos = this.campos[this.serie] || [];
      let camposAjustados = campos;

      if (this.componente_curricular === 10) {
        if (this.serie >= 1 && this.serie <= 3) {
          return campos;
        }
        const camposEspeciais = this.camposEspeciais[10] || [];
        camposAjustados = campos.filter((campo) => campo.id !== "objetivos_academicos");
        const campoEspecialObjetivosSociais = camposEspeciais.find(
          (campo) => campo.id === "objetivos_sociais_laborais"
        );
        if (campoEspecialObjetivosSociais) {
          camposAjustados.unshift(campoEspecialObjetivosSociais);
        }
        camposEspeciais.forEach((campoEspecial) => {
          if (
            campoEspecial.id !== "objetivos_sociais_laborais" &&
            !camposAjustados.some((campo) => campo.id === campoEspecial.id)
          ) {
            camposAjustados.push(campoEspecial);
          }
        });
      }

      return camposAjustados;
    },
  },

  created() {
    this.loadBasicData();
  },
  methods: {
    incrementId() {
      return this.currentId++;
    },
    resetComponenteCurricular() {
      this.componente_curricular = "";
    },
    mudarAba(id) {
      this.tab_atual = id;
      this.resetComponenteCurricular();
    },
    changeColor(obj) {
      let countClass = 0;
      if (this.notas[obj.id]?.nota_b1) countClass += 1;
      if (this.notas[obj.id]?.nota_b2) countClass += 1;
      if (this.notas[obj.id]?.nota_b3) countClass += 1;
      if (this.notas[obj.id]?.nota_b4) countClass += 1;
      return this.styleClass[countClass];
    },
    async loadBasicData() {
      try {
        this.$loaderService.open("Carregando dados...");
        const payload = await this.$services.inventarioDeHabilidadeEspecialService.syncAll(
          parseInt(this.$route.params.matricula_id, 10)
        );
        this.sistemaBNCC = payload.sistema.children;
        this.legendas = payload.sistema.legenda;
        this.opcoes = Object.keys(payload.sistema.opcoes);
        this.notas = payload.notas || {};
        this.aluno = payload.matricula.aluno;
        this.matricula = payload.matricula;
        this.turma = payload.matricula.turma;
        this.serie = payload.matricula.turma.series[0].id;
        if (this.serie >= 1 && this.serie <= 3) {
          this.tab_atual = 1;
        } else {
          this.tab_atual = 0;
        }
        this.resumos = { ...payload.resumos, matricula_id: this.matricula.id };
        this.gestao_de_aulas = payload.matricula.turma.gestao_de_aulas;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async fetchComponenteData(item) {
      try {
        const bimestreAtual = this.tab_atual;
        const response =
          await this.$services.inventarioDeHabilidadeEspecialService.syncAllComponenteCurricular({
            id: parseInt(this.$route.params.matricula_id, 10),
            componente_curricular: parseInt(item, 10),
            bimestre: parseInt(bimestreAtual, 10),
          });
        if (response.notas) {
          this.resumos = response.notas;
        } else {
          this.limparCamposFormulario();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    limparCamposFormulario() {
      this.resumos = {
        componente_curricular_id: null,
        objeto_conhecimento: null,
        metodologia: null,
        recursos_didaticos: null,
        avaliacao: null,
      };
    },
    async salvarNotas() {
      this.promiseCount += 1;
      try {
        const bimestreAtual = this.tab_atual;
        const matriculaId = parseInt(this.$route.params.matricula_id, 10);

        const resumos = {
          ...this.resumos,
          matricula_id: matriculaId,
        };

        await this.$services.inventarioDeHabilidadeEspecialService.salvarNotas(matriculaId, {
          notas: this.notas,
          resumos,
          bimestre: bimestreAtual,
          componente_curricular_id: this.componente_curricular,
        });
        this.$toast.info("Relatório Salvo...");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.promiseCount -= 1;
      }
    },
  },
};
</script>

<style scoped>
/* Adicione estilos conforme necessário */
</style>
